import React from "react";
import { graphql } from "gatsby";
import Layout from "../../layout/default/Layout";
import { Helmet } from "react-helmet";
import { Router, navigate } from "@reach/router";

import { SearchRentalBusinessPage } from "@coninsa-site/property";
import FloatingWhatsappBtn from "modules/home/components/FloatingWhatsappBtn";

function Landing({ data }) {
  return (
    <Layout
      headerAds={data?.drupal?.ads?.header}
      popupAds={data?.drupal?.ads?.popUp}
      footerAds={data?.drupal?.ads?.footer}
    >
      <Helmet>
        <title>Arrendamiento de inmuebles comerciales en Colombia | Coninsa</title>
        <meta name="description" content="Encuentra inmuebles comerciales en Colombia con Coninsa. Ofrecemos una amplia selección de locales, oficinas y espacios comerciales en arriendo y venta, ubicados en las mejores zonas. Impulsa tu negocio con las mejores propiedades comerciales y confía en nuestra experiencia para satisfacer tus necesidades empresariales." />
        <link rel="canonical" href="https://www.coninsa.co/arrendamientos/comercio/" />
      </Helmet>

      <FloatingWhatsappBtn path="https://api.whatsapp.com/send?phone=573123636333&text=Hola!" />

      <Router basepath="/arrendamientos/comercio">
        <SearchRentalBusinessPage path="*" navigate={navigate} />
      </Router>
    </Layout>
  );
}

export const query = graphql`
  query CommercePageQuery {
    drupal {
      ads: getAdsByUrl(path: "/arrendamientos/comercio") {
        header {
          id: nid
          image: fieldImage {
            url
          }
          url: fieldUrl {
            uri
            title
          }
        }
        popUp {
          id: nid
          image: fieldImage {
            url
          }
          url: fieldUrl {
            uri
            title
          }
        }
        footer {
          id: nid
          image: fieldImage {
            url
          }
          url: fieldUrl {
            uri
            title
          }
        }
      }
    }
  }
`;

export default Landing;
