import HeroBanner from "@coninsa-s2/banner";
import Container from "@coninsa-s2/container";
import Heading from "@coninsa-s2/heading";
import Button from "@coninsa-s2/button";

import bannerImage from "../../../images/property-banner.jpg";
import bannerMobileImage from "../../../images/property-banner-mobile.jpg";

import React, { useState, useEffect } from "react";
import { useLazyQuery } from "@apollo/client";

import CreatableSelect from "react-select/creatable";
import Select from "react-select";
import SearchModal from "../../components/SearchModal";

import LoupeIcon from "../../../../home/icons/loupe.inline.svg";

import {
  SEARCH_RENT_PROPERTIES_QUERY,
  SEARCH_RENT_PROPERTIES_COMMERCIAL_QUERY,
  SEARCH_BUY_PROPERTIES_QUERY,
} from "./queries";

import {
  getFacetIndexOptions,
  getFacetBuildingTypes,
  buildSearchQuery,
} from "@coninsa-site/project/src/utils";

function SearchTabs() {
  const [selectedQuery, setSelectedQuery] = useState(null);
  const [locations, setLocations] = useState([]);
  const [types, setTypes] = useState([]);
  const [locationValue, setLocationValue] = useState(null);
  const [typeValue, setTypeValue] = useState(null);
  const [isButtonDisabled, setButtonDisabled] = useState(true);
  const [toggle, setToggle] = useState(true);

  const [
    getRentProperties,
    {
      loading: rentLoading,
      error: rentError,
      data: rentData,
      refetch: rentRefetch,
    },
  ] = useLazyQuery(SEARCH_RENT_PROPERTIES_QUERY, { variables: { text: "" } });

  const [
    getRentCommecialProperties,
    {
      loading: rentCommercialLoading,
      error: rentCommercialError,
      data: rentCommercialData,
      refetch: rentCommercialRefetch,
    },
  ] = useLazyQuery(SEARCH_RENT_PROPERTIES_COMMERCIAL_QUERY, {
    variables: { text: "" },
  });

  const [
    getBuyProperties,
    {
      loading: buyLoading,
      error: buyError,
      data: buyData,
      refetch: buyRefetch,
    },
  ] = useLazyQuery(SEARCH_BUY_PROPERTIES_QUERY, { variables: { text: "" } });

  useEffect(() => {
    if (selectedQuery) {
      const runQuery = lazyQueries[selectedQuery];
      runQuery();

      // Reset select options.
      setLocationValue(null);
      setTypeValue(null);
      setButtonDisabled(false);

      setToggle(false);
    }
  }, [selectedQuery]);

  useEffect(() => {
    if (locationValue) {
      const runRefetchQuery = refetchQueries[selectedQuery];
      runRefetchQuery({ text: locationValue?.value || "" });

      // Reset type select
      setTypeValue(null);
    }
  }, [locationValue]);

  useEffect(() => {
    if (buyData?.search?.facets) {
      if (!locationValue) {
        setLocations(getFacetIndexOptions(0, buyData.search.facets));
      }

      setTypes(getFacetBuildingTypes(buyData.search.facets));
    }
  }, [buyData]);

  useEffect(() => {
    if (rentData?.search?.facets) {
      if (!locationValue) {
        setLocations(getFacetIndexOptions(0, rentData.search.facets));
      }

      setTypes(getFacetBuildingTypes(rentData.search.facets));
    }
  }, [rentData]);

  useEffect(() => {
    if (rentCommercialData?.search?.facets) {
      if (!locationValue) {
        setLocations(getFacetIndexOptions(0, rentCommercialData.search.facets));
      }

      setTypes(getFacetBuildingTypes(rentCommercialData.search.facets));
    }
  }, [rentCommercialData]);

  const toDoOptions = [
    { value: "rent_properties", label: "Arrendar un inmueble de vivienda" },
    {
      value: "rent_properties_commercial",
      label: "Arrendar un inmueble de comercio",
    },
    { value: "buy_properties", label: "Comprar inmuebles usados" },
  ];

  let lazyQueries = {
    rent_properties: getRentProperties,
    rent_properties_commercial: getRentCommecialProperties,
    buy_properties: getBuyProperties,
  };

  let refetchQueries = {
    rent_properties: rentRefetch,
    rent_properties_commercial: rentCommercialRefetch,
    buy_properties: buyRefetch,
  };

  const loading = buyLoading || rentLoading || rentCommercialLoading;

  const handleSubmit = (event) => {
    event.preventDefault();

    const url = buildSearchQuery(
      selectedQuery,
      locationValue?.value,
      typeValue?.value
    );

    window.location.replace(url, "_self");
  };

  return (
    <HeroBanner imageUrl={bannerImage} imageUrlMobile={bannerMobileImage}>
      <Container>
        <Heading tint="white" size="4xl" className="s2-banner__label">
          ¡Descubre tu inmueble ideal <br /> de vivienda o comercio!
        </Heading>
        <form className="s2-banner__form" onSubmit={handleSubmit}>
          <div style={{ flex: 0.75 }}>
            <Select
              className="s2-banner__select-first"
              classNamePrefix="s2-select"
              required
              placeholder="¿Qué quieres hacer?"
              options={toDoOptions}
              variant="search-left"
              onChange={(selected) => {
                if (selected) {
                  setSelectedQuery(selected.value);
                }
              }}
              autoFocus
            />
          </div>

          <div style={{ flex: 1.15 }}>
            <CreatableSelect
              className="s2-banner__select-second"
              classNamePrefix="s2-select"
              createOptionPosition="first"
              value={locationValue}
              options={locations}
              placeholder="Busca por ubicación o palabras clave"
              variant="search"
              isLoading={loading}
              isDisabled={loading || isButtonDisabled}
              formatCreateLabel={(text) => `Buscar '${text}'`}
              onChange={(selected) => {
                if (selected) {
                  setLocationValue(selected);
                }
              }}
            />
          </div>

          <div style={{ flex: 0.75 }}>
            <Select
              className="s2-banner__select-last"
              classNamePrefix="s2-select"
              value={typeValue}
              placeholder="Tipo de inmueble"
              options={types}
              isLoading={loading}
              isDisabled={loading || isButtonDisabled}
              variant="large"
              onChange={(selected) => {
                if (selected) {
                  setTypeValue(selected);
                }
              }}
            />
          </div>

          <Button className="s2-banner__btn-mobile" size="fluid">
            Buscar inmueble
          </Button>
          <button className="s2-banner__btn-desktop" type="submit">
            <LoupeIcon />
          </button>
        </form>
        <SearchModal />
      </Container>
    </HeroBanner>
  );
}

export default SearchTabs;
