import React, { useState } from "react";
import { useLazyQuery } from "@apollo/client";

import Modal from "@coninsa-s2/modal";
import Heading from "@coninsa-s2/heading";
import Button from "@coninsa-s2/button";

import "@coninsa-s2/form/src/input.styles.css";

import { useForm } from "react-hook-form";

import { SEARCH_PROPERTY_BY_CODE } from "../sections/banner/queries";

function SearchModal() {
  const { register, handleSubmit, reset } = useForm({});
  const [resultCount, setResultCount] = useState(-1);

  const [isOpen, setOpen] = useState(false);
  const closeModal = () => {
    reset();
    setResultCount(-1);
    setOpen(false);
  };

  const [getRentProperties, { loading }] = useLazyQuery(
    SEARCH_PROPERTY_BY_CODE,
    { variables: { code: "" } }
  );

  const onSubmit = async (data) => {
    return getRentProperties({ variables: { code: data.code } })
      .then(({ data }) => {
        const building = data?.building;

        if (building !== null) {
          window.open(data?.building?.propertyUrl?.path, "_self");
        } else {
          setResultCount(0);
        }
      })
      .catch((error) => {
        console.error(error.message);
      });
  };

  return (
    <div>
      <div className="flex justify-center md:justify-end items-center">
        <div className="text-white mr-2 font-medium text-sm md:text-base">
          ¿Tienes un código de inmueble?
        </div>
        <button
          onClick={() => setOpen(true)}
          className="bg-transparent rounded-lg border border-white text-white cursor-pointer text-sm md:text-base px-1 md:px-3 py-1 hover:bg-slate-300 hover:text-slate-700"
        >
          Ingresar código
        </button>
      </div>

      {isOpen && (
        <Modal header={<Heading weight="bold">Buscar por código</Heading>}>
          <form onSubmit={handleSubmit(onSubmit)}>
            <div className="mb-3 md:mb-6">
              <label className="block mb-3">
                Ingresa el código del inmueble
              </label>
              <input
                {...register("code")}
                className="s2-input w-full"
                id="code"
                placeholder="Código de inmueble"
                type="number"
                required
              />
              {resultCount === 0 && (
                <p className="text-red-600 pt-2">
                  Código incorrecto o no existe.
                </p>
              )}
            </div>
            <div className="flex justify-between">
              <Button variant="ghost" type="button" onClick={closeModal}>
                Cancelar
              </Button>

              <Button type="submit" disabled={loading}>
                {!loading ? "Buscar" : "Buscando ..."}
              </Button>
            </div>
          </form>
        </Modal>
      )}
    </div>
  );
}

export default SearchModal;
