import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { useMutation } from '@apollo/client'
import { Field } from 'ui/form'
import { useForm } from 'react-hook-form'
import { Box, Select, Grid, Input, Label, Button, Textarea, Spinner } from 'theme-ui'

import Toast from 'ui/Toast'
import DepartmentsField from './utils/DepartmentsField'
import { WEBFORM_SUBMIT } from './mutations'

const COLOMBIA_COUNTRY = 'Colombia'
const OTHER_COUNTRY = 'Otro'

const defaultValues = {
  country: COLOMBIA_COUNTRY,
}

const DefaultForm = ({ form, variant }) => {
  const {
    watch,
    formState: { isSubmitting },
    errors,
    handleSubmit,
    register,
    reset,
  } = useForm({ defaultValues })

  const [showingToast, showToast] = useState(false)
  const [webformSubmit] = useMutation(WEBFORM_SUBMIT)
  const { country, department } = watch(['country', 'department'])
  const countries = ['Colombia', 'Otro']
  const list = [
    {
      id: 's1',
      title: 'Gracias!',
      description: 'Su información fue enviada con éxito.',
      backgroundColor: '#16993b',
      icon: 'success',
    },
  ]

  const onSubmit = ({ fullName, ...rawData }) => {
    const values = JSON.stringify({
      full_name: fullName,
      webform_id: form.id,
      ...rawData,
    })

    return new Promise((resolve, reject) => {
      webformSubmit({ variables: { values } })
        .then(response => {
          const { errors: errorsForm } = response.data.submitForm

          if (errorsForm.length) {
            console.error(errorsForm)
            return
          }

          reset()
          showToast(true)
          resolve(response)
        })
        .catch(error => {
          console.error(error.message)
          reject(error)
        })
    })
  }

  return (
    <Grid columns={['ifr', '60% 40%']}>
      <Box sx={{ maxWidth: [null, '60%'] }}>
        <p>
          <strong>
            ¿Has imaginado el espacio de tus sueños? Nuestro equipo espera por ti.
            Déjanos tus datos y permítenos acompañarte en el diseño o construcción
            del espacio que necesitas.
          </strong>
        </p>

        <p>
          <strong>Antioquia número único:</strong>
          <br />
          (604) 448 88 28 Ext: 339.
        </p>
        <p>Fax: (604) 448 88 28 Ext. 5</p>
        <p>Dirección: Centro: Cl 55 No. 45 55</p>
      </Box>

      <form onSubmit={handleSubmit(onSubmit)}>
        {showingToast && (
          <Toast
            toastList={list}
            autoDelete
            autoDeleteTime='3000'
            position='bottom-right'
          />
        )}
        <Field error={errors?.fullName}>
          <Input
            placeholder='Tu nombre completo'
            type='text'
            variant={`input.${variant}`}
            {...register('fullName')}
            required
          />
        </Field>

        <Field>
          <Label>País</Label>
          <Select
            type='text'
            {...register('country')}
            variant={`select.${variant}`}
            required
          >
            {countries.map(name => (
              <option value={name}>{name}</option>
            ))}
          </Select>
        </Field>

        {country === COLOMBIA_COUNTRY && (
          <DepartmentsField>
            {(departments, getCities) => (
              <Grid columns={2}>
                <Field>
                  <Label>Departamento</Label>
                  <Select
                    placeholder='Departmento'
                    {...register('department')}
                    variant={`select.${variant}`}
                    required
                  >
                    <option key='empty-deparment' value=''>
                      - Seleccionar -
                    </option>

                    {departments.map(name => (
                      <option key={name} value={name}>
                        {name}
                      </option>
                    ))}
                  </Select>
                </Field>

                <Field>
                  <Label>Ciudad</Label>
                  <Select
                    disabled={!department}
                    {...register('city')}
                    variant={`select.${variant}`}
                  >
                    <option key='empty-city' value=''>
                      - Seleccionar -
                    </option>

                    {getCities(department).map(name => (
                      <option key={name} value={name}>
                        {name}
                      </option>
                    ))}
                  </Select>
                </Field>
              </Grid>
            )}
          </DepartmentsField>
        )}

        {country === OTHER_COUNTRY && (
          <Field>
            <Label>Ciudad</Label>
            <Input
              type='text'
              {...register('city')}
              variant={`input.${variant}`}
              required
            />
          </Field>
        )}

        <Grid columns={2} mb='small'>
          <Input
            placeholder='Correo Electrónico'
            type='email'
            {...register('email')}
            variant={`input.${variant}`}
            required
          />

          <Input
            placeholder='Celular'
            type='tel'
            {...register('phone')}
            variant={`input.${variant}`}
          />
        </Grid>

        <Field>
          <Textarea
            rows={5}
            placeholder='Escribe tu comentario, dudas o preguntas.'
            {...register('comments')}
            variant={`textarea.${variant}`}
            required
          />
        </Field>

        <Button
          sx={{ width: ['100%', 'auto'], fontSize: 1 }}
          size='small'
          variant='secondary'
          disabled={isSubmitting}
        >
          {isSubmitting ? (
            <Spinner variant='styles.spinner' size='20px' />
          ) : (
            'Contactarme'
          )}
        </Button>
      </form>
    </Grid>
  )
}

DefaultForm.defaultProps = {
  variant: 'darkly', // 'default',
}

DefaultForm.propTypes = {
  form: PropTypes.shape({
    id: PropTypes.string,
  }).isRequired,
  variant: PropTypes.string,
  id: PropTypes.string.isRequired,
}

export default DefaultForm
