import React from 'react'
import PropTypes from 'prop-types'
import { Splide, SplideSlide } from '@splidejs/react-splide'
import { Box } from 'theme-ui'

import { Section } from 'components'
import { normalizeCanon } from '../utils'
import Heading from "@coninsa-s2/heading";

import EntityPropertyCard from '@coninsa-site/property/src/enhancers/EntityPropertyCard.tsx'

const getNormalizedLocationProperty = (location = {}) =>
  [
    location?.entity?.parent[0]?.entity?.parent[0]?.entity?.name,
    location?.entity?.parent[0]?.entity?.name,
    location?.entity?.name
  ].join(', ')

function RelatedProperties({ properties, ...props }) {

  if (!properties.length) {
    return null
  }

  const options = {
    arrows: false,
    rewind: true,
    gap: '1rem',
    perPage: 3,

    breakpoints: {
      640: {
        gap: '.5rem',
        perPage: 1,
        padding: { left: 0, right: '1.5rem' },
      }
    }
  }

  // filter property only with at least one image.
  const propertiesWithImages = properties.filter(
    property => property?.images.length !== 0
  )

  if (propertiesWithImages.length === 0) {
    return null
  }

  return (
    <Section
      variant='grayly'
      {...props}
    >
      <Heading tint="teal" size="3xl" className="mb-6" condensed>
        Otros inmuebles
        <span className="is-highlighted"> en arriendo</span>
      </Heading>

      <Box sx={{ display: ['none', 'grid'], gridTemplateColumns: '1fr 1fr 1fr', gap: '1rem', justifyContent: 'center' }}>
        {propertiesWithImages.map(property => (
          <EntityPropertyCard
            key={property?.id}
            code={property?.code}
            price={
              normalizeCanon(property?.leaseValue, property?.saleValue)
            }
            bedrooms={property?.bedrooms}
            bathrooms={property?.bathrooms}
            url={property?.url?.path}
            area={property?.area}
            images={property?.images}
            serviceType={property?.serviceType}
            location={getNormalizedLocationProperty(property?.relatedLocation)}
            propertyType={property?.propertyType?.entity?.name}
            state={property?.state?.entity}
          />
        ))}
      </Box>

      <Box sx={{ display: ['block', 'none'] }}>
        <Splide options={options}>
          {propertiesWithImages.map(property => (

            <SplideSlide>
              <div className='pb-8 md:pb-0'>
                <EntityPropertyCard
                  key={property?.id}
                  code={property?.code}
                  price={
                    normalizeCanon(property?.leaseValue, property?.saleValue)
                  }
                  bedrooms={property?.bedrooms}
                  bathrooms={property?.bathrooms}
                  url={property?.url?.path}
                  area={property?.area}
                  images={property?.images}
                  serviceType={property?.serviceType}
                  location={getNormalizedLocationProperty(property?.relatedLocation)}
                  propertyType={property?.propertyType?.entity?.name}
                  state={property?.state?.entity}
                />
              </div>
            </SplideSlide>
          ))}
        </Splide>
      </Box>
    </Section >
  )
}

RelatedProperties.defaultProps = {
  properties: []
}

RelatedProperties.propTypes = {
  properties: PropTypes.arrayOf({})
}

export default RelatedProperties
