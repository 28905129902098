import React from "react";
import SortSelect from "@coninsa-s2/sort-select";

interface SearchSummaryProps {
  loading: boolean;
  resultCount: number;
  refetch: (any) => void;
  sortSettings: {
    fields: object;
    options: Array<{ label: string; value: string }>;
    defaultFields: Array<{ field: string; value: string }>;
  };
}

const SearchSummary: React.FC<SearchSummaryProps> = ({
  loading,
  resultCount,
  refetch,
  sortSettings,
}) => {
  const handleChange = (event) => {
    const selectedValue = event.target.value;
    const sortField = sortSettings.fields[selectedValue];

    if (sortField) {
      refetch({ sort: [ ...sortSettings.defaultFields, sortField] });
    } else {
      refetch({ sort: sortSettings.defaultFields });
    }
  };

  return (
    <div className="s2-search-summary">
      <div className="text-sm">
        {!loading && (
          <div>
            {resultCount > 1 && (
              <p>
                <span className="hidden md:inline-flex">Hemos encontrado </span> <strong>{resultCount}</strong> resultados
              </p>
            )}
            {resultCount === 1 && (
              <p>
                <span className="hidden md:inline-flex">Hemos encontrado </span> <strong>{resultCount}</strong> resultado
              </p>
            )}
            {resultCount === 0 && <p>No hay resultados</p>}
          </div>
        )}

        {loading && <p>Cargando ...</p>}
      </div>

      <div>
        <div className="flex items-center gap-2">
          <label className="text-sm">Ordenar por</label>
          <SortSelect onChange={handleChange}>
            {sortSettings.options.map((option) => (
              <option key={option.value} value={option.value}>
                {option.label}
              </option>
            ))}
          </SortSelect>
        </div>
      </div>
    </div>
  );
};

export default SearchSummary;
