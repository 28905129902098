import React from 'react';
import clsx from "clsx";

import "./styles.css";

function IconCard({
  featured = false,
  icon = null,
  ...props
}) {
  const dynamicClasses = {
    "s2-icon-card--featured": featured,
  };

  return (
    <div className={clsx("s2-icon-card", dynamicClasses)} {...props}>
      {featured && icon && (
        <div className="s2-icon-card__icon">
          <img src={icon} />
        </div>
      )}
      <div className="s2-icon-card__content">{props.children}</div>
    </div>
  );
}

export default IconCard;
