import React, { useState, useEffect } from "react";
import ConfettiPrimitive from "react-confetti";

function Confetti() {
  const [screenSize, setScreenSize] = useState(null);
  const [showConfetti, setShowConfetti] = useState(true);

  useEffect(() => {
    const width = window.screen.width;
    const height = window.screen.height;

    setScreenSize({ width, height });
  }, []);

  useEffect(() => {
    const timer = setTimeout(() => {
      setShowConfetti(false);
    }, 5000);

    return () => {
      clearTimeout(timer);
    };
  }, [screenSize]);

  return (
    <div>
      {showConfetti && screenSize && (
        <ConfettiPrimitive
          width={screenSize.width}
          height={screenSize.height}
          numberOfPieces={400}
        />
      )}
    </div>
  );
}

export default Confetti;
