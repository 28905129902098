import React from "react";
import clsx from "clsx";

import "./styles.css";

function ImageCard({ image = null, slipped = false, className, ...props }) {
  const dynamicClasses = {
    "s2-image-card--slipped": slipped,
  }
  return (
    <div className={clsx("s2-image-card", dynamicClasses)}>
      <div className="s2-image-card__image">
        <img src={image} />
      </div>
      <div className="s2-image-card__content">{props.children}</div>
    </div>
  );
}

export default ImageCard;
