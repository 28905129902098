import React from 'react'
import { Box } from 'theme-ui'
import { Field } from 'ui/form'

const TermsAndConditions = () => (
    <Field>
      Al enviar el formulario estás aceptando los
      <Box
        as='a'
        sx={{ color: 'accent', textDecoration: 'none' }}
        href='https://www.coninsa.co/terminos-y-condiciones-concursos-y-promociones?action'
        target='_blank'
      >
        {' Términos y Condiciones '}
      </Box>
      del sitio web y la
      <Box
        as='a'
        sx={{ color: 'accent', textDecoration: 'none' }}
        href='https://www.coninsa.co/politica-de-tratamiento-de-datos-personales-de-coninsa-ramon-h-sa'
        target='_blank'
      >
        {' Política de Tratamiento de Datos de Coninsa Ramón H. S.A.'}
      </Box>
    </Field>
  )

export default TermsAndConditions
