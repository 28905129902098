import React from "react";

import SearchPropertyCard from "../../enhancers/SearchPropertyCard";
import SearchPropertyPopup from "../../enhancers/SearchPropertyPopup";

import SearchBar from "./sections/search-bar";
import SearchView from "@coninsa-site/project/src/pages/search-view/search-view";

import searchRentalHousingConfigs from "../../facets/search-rental-housing";

interface SearcRentalPageProps {
  navigate: (string) => void;
}

export const SearcRentalPage: React.FC<SearcRentalPageProps> = ({
  navigate = (url) => console.log(url),
}) => {
  return (
    <SearchView
      navigate={navigate}
      searchBar={SearchBar}
      itemCard={SearchPropertyCard}
      popupCard={SearchPropertyPopup}

      configs={searchRentalHousingConfigs}
    />
  );
};

export default SearcRentalPage;
