
export default [
  {
    id: 'project-card-1',
    subtitle: 'Servicios de',
    title: 'arrendamientos y venta de usados',
    description: 'Encuentra tu inmueble ideal de comercio o vivienda más de 2000 inmuebles para conocer.',
    url: '/arrendamientos',
    tint: 'lime',
  },

  {
    id: 'project-card-2',
    subtitle: 'Portafolio de',
    title: 'proyectos nuevos',
    description: 'Explora nuestra sección de proyectos y encuentra el lugar ideal para vivir o invertir.',
    url: '/proyectos',
    tint: 'green',
  },
  {
    id: 'project-card-3',
    subtitle: 'Servicios de diseño',
    title: 'Arquitectónico y Técnico',
    description: 'Nuestros servicios van desde el diseño urbanístico hasta el arquitectónico y de interiorismo.',
    url: '/arquitectura',
    tint: 'teal',
  },

  {
    id: 'project-card-4',
    subtitle: 'Servicios de construcción',
    title: 'de edificaciones e infraestructura',
    description: 'Ofrecemos soluciones innovadoras y competitivas como respuesta a sus necesidades.',
    url: '/construccion',
    tint: 'blue',
  },
]
