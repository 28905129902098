import React from "react";

import Banner from "../sections/banner";
import Buildings from "../sections/buildings";
import PropertyCards from "../sections/property-cards";
import OurExperience from "@coninsa-site/home/src/sections/our-experience";
import OurDifferentials from "../sections/our-differentials";
import Mcv from "../sections/mcv";
import { Helmet } from 'react-helmet'

import Button from "@coninsa-s2/button";
import StarIcon from "./../../icons/star.inline.svg";

function PropertyPage({ properties = [] }) {
  return (
    <div>
      {/* <Button href="/recomendados" target="_blank" vertically >
        <StarIcon />
        Ver recomendados
      </Button> */}
      <Helmet>
        <title>Arrendamientos de Inmuebles en Colombia | Coninsa</title>
        <meta name="description" content="Descubre las mejores opciones de arrendamiento de inmuebles en Colombia con Coninsa. Ofrecemos una amplia variedad de propiedades en alquiler, desde apartamentos y casas hasta oficinas y locales comerciales, con las mejores ubicaciones y precios competitivos. Confía en nuestra experiencia y servicio de calidad para encontrar el espacio perfecto para ti." />
        <link rel="canonical" href="https://www.coninsa.co/arrendamientos/" />
      </Helmet>
      <div>
        <Banner />
        <Buildings />
        <PropertyCards properties={properties} />
        <OurExperience />
        <OurDifferentials />
        <Mcv />
      </div>
    </div>
  );
}

export default PropertyPage;
