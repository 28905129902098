import React, { useState, useEffect } from "react";
import axios from "axios";
import moment from "moment";

import mockedData from "./mock";
import Section from "@coninsa-s2/section";
import BlogCard from "@coninsa-s2/blog-card";
import Container from "@coninsa-s2/container";
import Heading from "@coninsa-s2/heading";
import Button from "@coninsa-s2/button";

function forceToHttps(url = "") {
  // return url.replace("http:", "https:");
  return url;
}

moment.locale("es");

const ARTICLES_API = "https://blog.coninsa.co/api/articles/coninsa/home";

const blogNormalizer = (data) => ({
  title: data.title,
  absolute_url: data.url,
  date: moment(data.created).format("D MMM YY"),
  author: data.author,
  image: data.image,
});

const blogsMap = (blogs = []) => blogs.map(blogNormalizer);

const Block = () => {
  const [blogs, setBlogs] = useState(mockedData);

  useEffect(() => {
    const fetchBlogs = async () => {
      try {
        const response = await axios.get(ARTICLES_API);
        const normalizedBlogs = blogsMap(response.data);

        setBlogs(normalizedBlogs);
      } catch (error) {
        console.error(error);

        // Temporary solution.
        const normalizedData = blogsMap(mockedData.objects);
        setBlogs(normalizedData);
      }
    };

    fetchBlogs();
  }, []);

  const styleSettingsCards = [
    "md:col-span-2 md:row-span-2",
    "md:col-span-2 md:row-span-1",
    "",
    "",
  ];

  return (
    <div>
      <Section tint="dark-blue">
        <Container className="flex gap-8 flex-col md:flex-row md:justify-between items-start  md:items-center">
          <Heading tint="white" size="2xl" condensed>
            Entérate de lo último con
            <br />
            <strong className="font-semibold">
              NUESTRO BLOG CONSTRUIMOS BIENESTAR
            </strong>
          </Heading>

          <Button
            outline
            onClick={() => window.open("https://blog.coninsa.co/", "_blank")}
          >
            Todos los artículos
          </Button>
        </Container>
      </Section>

      <div className="hidden md:grid grid-cols-2 md:grid-cols-4 grid-rows-[285px]">
        {blogs.map((blog, key) => (
          <BlogCard
            key={key}
            className={styleSettingsCards[key]}
            imageUrl={forceToHttps(blog.image)}
          >
            <Heading size="lg" tint="white" condensed>
              <a href={forceToHttps(blog.absolute_url)} alt={blog.title} target="_blank">
                <strong>{blog.title}</strong>
              </a>
            </Heading>
            <time>{blog.date}</time>
          </BlogCard>
        ))}
      </div>
    </div>
  );
};

export default Block;
