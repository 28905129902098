import referImg from '../../../images/refer.png'
import workProgressImg from '../../../images/work-progress.png'
import investmentImg from '../../../images/investment.png'

export default [
  {
    id: "service-card-1",
    image: referImg,
    title: "Refiere nuestros proyectos",
    description: 'Tus amigos y familiares merecen las mejores recomendaciones de inversión, cuéntales acerca de nuestros proyectos.',
    url: '/proyectos-referidos',
  },
  {
    id: "service-card-2",
    image: workProgressImg,
    title: "Avance de obra de proyectos",
    description: 'Descubre el progreso de tu proyecto y sumérgete en cada fase de la construcción.',
    url: 'https://busquedas.coninsa.co/proyectos/avance-de-obra',
  },
  {
    id: "service-card-3",
    image: investmentImg,
    title: "Invierte desde el Exterior",
    description: 'Si estás en el exterior conoce paso a paso cómo puedes invertir en Colombia y hacer realidad tu sueño de comprar tu inmueble.',
    url: '/desde-el-exterior',
  },
]
