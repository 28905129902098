import React from "react";
import clsx from "clsx";
import "./styles.css";

function DialogBox({ width = "large", tint, className, ...props }) {
  const classes = {
    "s2-dialog-box": true,
    [`s2-dialog-box--${tint}`]: tint,
    [`s2-dialog-box--${width}`]: width,
  };

  return (
    <div className={clsx(classes, className)}>
      {props.children}
      <span className="s2-dialog-box__shape"></span>
    </div>
  );
}

export default DialogBox;
