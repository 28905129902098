import { gql } from "@apollo/client";

export const SEARCH_QUERY = gql`
  query SearchProjects(
    $text: [String]!
    $and_conditions: [ConditionInput]
    $offset: Int!
    $sort: [SortInput]
  ) {
    search: searchAPISearch(
      index_id: "search_projects"
      facets: [
        {
          field: "department_project"
          min_count: 0
          missing: false
          operator: "="
          limit: 1000
        }
        {
          field: "property_type"
          min_count: 1
          missing: false
          operator: "="
          limit: 100
        }
        {
          field: "field_property_type"
          min_count: 1
          missing: false
          operator: "="
          limit: 100
        }
        {
          field: "field_state"
          min_count: 1
          missing: false
          operator: "="
          limit: 100
        }
        {
          field: "field_immediate_delivery"
          min_count: 1
          missing: false
          operator: "="
          limit: 1000
        }
        {
          field: "commerce_housing"
          min_count: 1
          missing: false
          operator: "="
          limit: 1000
        }
      ]
      condition_group: {
        conjunction: AND
        groups: [
          {
            conjunction: AND
            conditions: [
              { name: "field_sale_price_from", value: "0", operator: ">" }
            ]
          }
          {
            conjunction: OR
            conditions: [
              { name: "status", value: "1", operator: "=" }
              { name: "field_final_publication", value: "1", operator: "=" }
              { name: "field_for_sale", value: "1", operator: "=" }
              { name: "field_sale_price_from", value: "", operator: "<>" }
            ]
          }
        ]
      }

      conditions: $and_conditions
      fulltext: { keys: $text, conjunction: "AND" }
      range: { offset: $offset, limit: 10 }
      sort: $sort
    ) {
      result_count
      facets {
        name
        values {
          count
          filter
        }
      }
      documents {
        index_id
        relevance
        ... on SearchProjectsDoc {
          id: project_id
          url: project_url
          imageUrl: project_image
          code: project_code
          name: field_project_description
          areaFrom: field_area_from
          areaTo: field_area_to
          price: field_sale_price_from
          location: project_location
          customNote: field_custom_note
          state: field_state
          isVis: field_immediate_delivery
          brandUrl: brand_url
          latLong: field_lat_long
          commerceHousing: commerce_housing
        }
      }
    }
  }
`;
