import walletIcon from "./icons/wallet.svg"
import homeIcon from "./icons/home.svg"
import craneIcon from "./icons/crane.svg"

export default [
  {
    id: 'quick-item-1',
    tint: 'dark-blue',
    icon: walletIcon,
    title: "Mi Coninsa Virtual",
    description:
      "Realiza el pago de tu factura. Consulta tu estado de cuenta y otras transacciones en Mi Coninsa Virtual.",
    url: "https://miconinsavirtual.coninsa.co",
  },
  {
    id: 'quick-item-2',
    tint: 'green',
    icon: homeIcon,
    title: "Venta de usados",
    description:
      "Diligencia un pequeño formulario y nos encargaremos de promocionar y vender tú inmueble.",
    url: "/venta-de-usados",
  },
  {
    id: 'quick-item-3',
    tint: 'mint',
    icon: craneIcon,
    title: "Avances de obra",
    description:
      "Descubre el progreso de tu proyecto y sumérgete en cada fase de la construcción.",
    url: "https://busquedas.coninsa.co/proyectos/avance-de-obra",
  },
];
