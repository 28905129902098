import React from "react"
import { Landing as AdvertisePropertyLanding } from '@coninsa-site/advertise-property';

import Layout from "../../layout/default/Layout"

export default function Landing() {
  return (
    <Layout>
      <AdvertisePropertyLanding />
    </Layout>
  )
}
