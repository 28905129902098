import React, { useState } from "react";
import { useForm } from "react-hook-form";
import axios from "axios";

import Button from "@coninsa-s2/button";

function SuggestionForm({ baseUrl }) {
  const { register, handleSubmit, reset } = useForm({});
  const [submitted, setSubmitted] = useState(false);

  const onSubmit = async (data) => {
    const zohoInfo = {
      data: [
        {
          First_Name: data.name,
          Last_Name: data.last_name,
          Owner: { id: "2528071000008997151" },
          Email: data.email,
          Observaciones_del_cliente: data.observations,
          Mobile: data.phone,
          Sucursal: "ABR",
          Regional: "Colombia",
          Origen_de_Informaci_n: "Digital",
          Medio_Publicitario: "www.coninsa.co",
          Creador_API: "www.coninsa.co",
          Tipo_de_Canal: "Digital",
          Tipo_de_Contacto: "Otros",
          Layout: {
            id: "2528071000003925032",
          },
          Servicio_interes: "Arriendo", //Opciones Arriendo o Venta
        },
      ],
    };

    const url = "https://api.coninsa.co/api/v2/zohocrm-create-lead";

    return new Promise((resolve, reject) => {
      axios
        .post(url, zohoInfo)
        .then((response) => {
          reset();
          setSubmitted(true);
          resolve(response);
        })
        .catch((error) => {
          console.log(error);
          reject(error);
        });
    });
  };

  return (
    <div>
      {!submitted && (
        <form className="md:w-3/6 md:mx-auto" onSubmit={handleSubmit(onSubmit)}>
          <div className="form-content">
            <div className="pt-12 mb-5">
              <h3 className="text-lg font-bold text-s2-blue mb-2">
                No hay resultados
              </h3>
              <p className="text-slate-600 text-sm">
                ¿No has encontrado lo que buscabas? Déjanos tus datos para
                ayudarte en tu búsqueda.
              </p>
            </div>
            <div className="flex flex-col gap-4">
              <div className="grid grid-cols-2 gap-4 md:flex-row">
                <input
                  className="s2-input"
                  type="text"
                  {...register("name")}
                  id="name"
                  placeholder="Nombres"
                  required
                />
                <input
                  className="s2-input"
                  type="text"
                  {...register("last_name")}
                  id="last_name"
                  placeholder="Apellidos"
                  required
                />
              </div>

              <div className="grid grid-cols-2 gap-4 md:flex-row">
                <input
                  className="s2-input"
                  type="tel"
                  {...register("phone")}
                  id="phone"
                  placeholder="Número de celular"
                  required
                />
                <input
                  className="s2-input"
                  type="text"
                  {...register("email")}
                  id="email"
                  placeholder="Correo electrónico"
                  required
                />
              </div>

              <div className="">
                <textarea
                  className="s2-textarea w-full"
                  rows={5}
                  id="observations"
                  {...register("observations")}
                  placeholder="Escribe lo que buscabas"
                  required
                ></textarea>
              </div>

              <div className="flex justify-end">
                <Button shape="square">Enviar</Button>
              </div>
            </div>
          </div>
        </form>
      )}

      {submitted && (
        <div className="pt-12">
          <p className="text-center text-slate-800 font-medium mb-6">
            Gracias por confiar en nosotros, pronto un asesor se comunicará con
            usted.
          </p>

          <div className="flex justify-center">
            <Button href={baseUrl} shape="square" variant="ghost">Hacer otra búsqueda</Button>
          </div>
        </div>
      )}
    </div>
  );
}

export default SuggestionForm;
