import React from "react";
import { clsx } from "clsx";
import "./styles.css";

interface ButtonProps {
  shape?: "round" | "rounded" | "square";
  tint?: "dark-blue" | "lime" | "teal" | "white";
  size?: "normal" | "fluid" | "compact" | "large";
  vertically?: boolean;
  inverted?: boolean;
  outline?: boolean;
  samePadding?: boolean;

  variant?: "default" | "ghost" | "facet" | "static" | "underline";

  href?: string;
  type?: "button" | "submit";
  onClick: () => void;
  className?: string;
  disabled?: boolean;
  children?: React.ReactNode;
}

const Button: React.FC<ButtonProps> = ({
  size = "normal",
  inverted = false,
  outline = false,
  vertically = false,
  samePadding = false,
  tint = "dark-blue",
  shape = "round",
  variant,

  className,
  ...props
}) => {
  const dynamicClasses = {
    [`s2-button--${size}`]: size,
    [`s2-button--${shape}`]: shape,
    [`s2-button--${tint}`]: tint,

    "s2-button--inverted": inverted,
    "s2-button--outline": outline,
    "s2-button--vertically": vertically,
    "s2-button--same-padding": samePadding,

    [`s2-button--${variant}`]: variant,
  };

  const ElementTag = props?.href ? "a" : "button";

  return (
    <ElementTag
      className={clsx("s2-button", dynamicClasses, className)}
      {...props}
    >
      {props.children}
    </ElementTag>
  );
};

export default Button;
