import { graphql } from 'gatsby'
import PropTypes from 'prop-types'
import React from 'react'

import { paragraphResolver } from '../paragraphs'
import { mapResolver } from '../mappers'

import { layoutResolver } from '../layout'

function PageTemplate(props) {
  const { layout } = props?.pageContext
  const { node } = props?.data.drupal
  const rawParagraphs = node?.paragraphs || []
  const rawHero = node?.hero || null

  const paragraphs = rawParagraphs.map(mapResolver)
  const hero = mapResolver(rawHero)

  const Layout = layoutResolver(layout)

  return (
    <Layout footerAds={node?.footerAds} headerAds={node?.headerAds} popupAds={node?.popUpAds}>
      {rawHero && paragraphResolver(hero)}
      {paragraphs.map(paragraphResolver)}
    </Layout>
  )
}

PageTemplate.propTypes = {
  pageContext: PropTypes.shape({
    layout: PropTypes.shape({}),
  }).isRequired,
  data: PropTypes.shape({}).isRequired,
}

export default PageTemplate

export const pageQuery = graphql`
  query page($id: String!) {
    drupal {
      node: nodeById(id: $id) {
        id: nid

        headerAds: ads(type: "header") {
          id: nid
          image: fieldImage {
            url
          }
          url: fieldUrl {
            uri
            title
          }
        }
        popUpAds: ads(type: "pop_up") {
          id: nid
          image: fieldImage {
            url
          }
          url: fieldUrl {
            uri
            title
          }
        }
        footerAds: ads(type: "footer") {
          id: nid
          image: fieldImage {
            url
          }
          url: fieldUrl {
            uri
            title  
          }
        }

        ... on Drupal_NodePage {
          __typename
          hero: fieldHero {
            entity {
              id
              ...ParagraphHero
              ...ParagraphHeroSlider
            }
          }

          paragraphs: fieldParagraphs {
            entity {
              id

              ...ParagraphCta
              ...ParagraphSection
              ...ParagraphHorizontalSection
            }
          }
        }
      }
    }
  }
`
