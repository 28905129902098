export default async function zohoAPI(data) {
  const hasSavings = parseInt(data.has_savings) === 1 ? "Si" : "No";
  const url = "https://api.coninsa.co/api/v2/zoho/leads/project";

  const zohoInfo = {
    "first_name": data.user.names,
    "last_name": data.user.last_name,
    "mobile": data.user.phone,
    "id_project": 2528071000001224071,
    "email": data.user.phone,
    "owner": 2528071000000321201,
    "region": "Medellin",
    "Observaciones_del_cliente": `Cesantías: ${hasSavings}`
  }

  await fetch(url, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(zohoInfo),
  })
}

export async function getProject(code) {
  const options = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
  };
  const response = await fetch(`https://admindrupal.coninsa.co/cotizador/projects/${code}`, options);
  const dataResponse = await response.json();

  return dataResponse;
}
