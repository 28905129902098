import React from 'react';
import { clsx } from "clsx";

import "./styles.css";

function Container({ className, ...props }) {
  return <div className={clsx('s2-container', className)}>{props.children}</div>
}

export default Container;
