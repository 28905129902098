import React, { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import axios from 'axios'
import PropTypes from 'prop-types'

import Button from "@coninsa-s2/button"

import { Box, Input, Checkbox, Label } from 'theme-ui'

import { Divider, Icon } from 'ui'
import { getNormalizerServiceType } from '../utils'
import HabeasData from 'component-v2/HabeasData'

const getNormalizedBranchOffice = region => {
  let sucursal
  let regional

  switch (region) {
    case 'Antiquia':
      sucursal = 'ABR SABANETA'
      regional = 'Medellín'
      break
    case 'Cundinamarca':
      sucursal = 'ABR BOGOTA SANTA BARBARA'
      regional = 'Bogotá'
      break
    case 'Atlántico':
      sucursal = 'ABR BARRANQUILLA BAHIA'
      regional = 'Barranquilla'
      break
    case 'Bolívar':
      sucursal = 'ABR BARRANQUILLA BAHIA'
      regional = 'Barranquilla'
      break
    case 'Magdalena':
      sucursal = 'ABR BARRANQUILLA BAHIA'
      regional = 'Barranquilla'
      break
    default:
      sucursal = 'ABR SABANETA'
      regional = 'Medellín'
      break
  }
  return [sucursal, regional]
}

const getNormalizedOwnerId = id => {
  let adviserPhone
  let idOwner

  switch (id) {
    case 'Antioquia':
      adviserPhone = '3009122060'
      idOwner = '2528071000008997151'
      break
    case 'Atlántico':
      adviserPhone = '3009126642'
      idOwner = '2528071000008997151'
      break
    case 'Bolívar':
      adviserPhone = '3009126642'
      idOwner = '2528071000008997151'
      break
    case 'Magdalena':
      adviserPhone = '3009126642'
      idOwner = '2528071000008997151'
      break
    case 'Cundinamarca':
      adviserPhone = '3009126657'
      idOwner = '2528071000008997151'
      break
    default:
      adviserPhone = '3004102971'
      idOwner = '2528071000094806674'
      break
  }

  return [adviserPhone, idOwner]
}

function getZohoProspectId(response) {
  const status = response?.status

  return status === 200 ? response?.data?.data?.[0]?.details?.id : null
}

function BuildingContactForm({
  code,
  path,
  canon,
  propertyType,
  region,
  serviceType,
}) {
  const [lat, setLat] = useState()
  const [lon, setLon] = useState()
  const { register, handleSubmit, reset } = useForm({})

  useEffect(() => {
    navigator.geolocation.getCurrentPosition(position => {
      setLat(position.coords.latitude)
      setLon(position.coords.longitude)
    })
  }, [])

  const location = getNormalizedBranchOffice(region)
  const ownerId = getNormalizedOwnerId(region)

  const onSubmit = async data => {
    const zohoInfo = {
      data: [
        {
          First_Name: data.name,
          Last_Name: data.last_name,
          Owner: ownerId[1],
          Email: data.email,
          Sucursal: location[0],
          Regional: location[1],
          Tipo_de_Inmueble_de_Inter_s: propertyType,
          Servicio_interes: getNormalizerServiceType(serviceType),
          Origen_de_Informaci_n: 'Digital',
          Medio_Publicitario: 'www.coninsa.co',
          Mobile: data.phone,
          Canon_Precio_desde: Math.floor(canon),
          Tipo_de_Canal: 'Portales web (virtuales)',
          Tipo_de_Contacto: 'Otros',
          log_integracion: code,
          Layout: { id: '2528071000003925032' },
        },
      ],
    }

    const hubspotInfo = {
      properties: [
        {
          property: 'email',
          value: data.email,
        },
        {
          property: 'firstname',
          value: data.name,
        },
        {
          property: 'lastname',
          value: data.last_name,
        },
        {
          property: 'website',
          value: `https://www.coninsa.co${path}`,
        },
        {
          property: 'company',
          value: 'company',
        },
        {
          property: 'phone',
          value: data.phone,
        },
        {
          property: 'fuente_offline',
          value: 'website',
        },
        {
          property: 'proyecto_de_interes',
          value: '',
        },
        {
          property: 'latitud',
          value: lat,
        },
        {
          property: 'longitud',
          value: lon,
        },
      ],
    }

    const email = data?.email
    const leaseUrl = 'https://api.coninsa.co/api/v2/zohocrm-create-lead'
    const saleUrl = `https://api.coninsa.co/api/v2/hubspot-create-contact/${email}`
    const zohoKardexUrl = 'https://api.coninsa.co/api/v2/zoho-kardex'

    const isLeasing = serviceType === 'AR'
    const url = leaseUrl //isLeasing ? leaseUrl : saleUrl
    const info = zohoInfo //isLeasing ? zohoInfo : hubspotInfo

    return axios
      .post(url, info)
      .then(response => {
        reset()
        window.location.replace(`/gracias?destination=${window.location.pathname}`)

        // Link lease property with Zoho kardex.
        //if (isLeasing) {
        const prospectId = getZohoProspectId(response)

        axios.post(zohoKardexUrl, {
          id_inmueble: code,
          id_prospecto: prospectId,
          origin: 'Leads',
        })
        //}
      })
      .catch(error => {
        console.log(error)
      })
  }

  return (
    <Box as='form' onSubmit={handleSubmit(onSubmit)}>
      <Divider className='mb-4' />

      <h4 className="font-bold mb-4">Contáctame</h4>

      <div className='flex flex-col gap-2' register={register}>
        <Input {...register('name')} id='name' placeholder='Nombres' required />
        <Input
          {...register('last_name')}
          id='last_name'
          placeholder='Apellidos'
          required
        />
        <Input
          {...register('phone')}
          id='phone'
          placeholder='Número de contacto'
          required
        />
        <Input
          {...register('email')}
          id='email'
          placeholder='Correo electrónico'
          required
        />

        <HabeasData />

        <Label>
          <Checkbox {...register('accept_conditions')} required />
          <Box sx={{ fontSize: [1, 0], textAlign: 'left' }}>
            <span>Autorizo el tratamiento y la </span>
            <Box
              as='a'
              href='https://www.coninsa.co/politica-de-tratamiento-de-datos-personales-de-coninsa-ramon-h-sa'
              sx={{ color: 'primary', textDecoration: 'none' }}
              target='_blank'
            >
              política de datos personales
            </Box>
          </Box>
        </Label>
      </div>

      <Button size='fluid'>
        <Icon name='fi-phone' />
        Recibir una llamada
      </Button>
    </Box>
  )
}

BuildingContactForm.defaultProps = {
  canon: '',
}

BuildingContactForm.propTypes = {
  path: PropTypes.string.isRequired,
  canon: PropTypes.string,
  propertyType: PropTypes.string.isRequired,
  region: PropTypes.string.isRequired,
  serviceType: PropTypes.string.isRequired,
}

export default BuildingContactForm
