import React from "react";

import Section from "@coninsa-s2/section";
import Container from "@coninsa-s2/container";
import Heading from "@coninsa-s2/heading";
import Button from "@coninsa-s2/button";
import IconCard from "@coninsa-s2/icon-card";

import data from "./data.js";

import ArrowRightIcon from "../../../../home/icons/arrow-right.inline.svg";

export default function () {
  return (
    <Section tint="light-gray">
      <Container>
        <Heading as="h1" tint="teal" size="4xl" condensed className="mb-10">
          Portafolio de servicios <br />
          <span className="is-highlighted">
            de arrendamiento y venta de usados
          </span>
        </Heading>
        <div className="grid gap-4 md:grid-cols-2 lg:grid-cols-4">
          {data?.map((item) => (
            <IconCard key={item.id} tint={item.tint} icon={item.icon} featured>
              <Heading tint={item.tint} size="2xl" condensed>
                <span className="is-highlighted"> {item?.title}</span> <br />
                {item?.subtitle}
              </Heading>
              <Button href={item?.url} target="_blank">
                Conoce más
              </Button>
              <a href={item?.url} target="_blank">
                <ArrowRightIcon className="s2-icon-card__arrow-icon" />
              </a>
            </IconCard>
          ))}
        </div>
      </Container>
    </Section>
  );
}
